import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"


const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Nie znaleziono strony" />
    <h1>Błąd 404</h1>
    <p>Ups, coś poszło nie tak</p>
  </Layout>
)

export default NotFoundPage
